import Image from 'next/image';
import React from 'react';
import { IPostCardProps } from '@/interfaces/card';
import Link from 'next/link';

interface IItemRightTable extends IPostCardProps {
  isThumbnail?: boolean;
}

const ItemRightTable = ({
  category,
  slug,
  id,
  isThumbnail,
  thumbnail,
  title,
}: IItemRightTable) => {
  return (
    <Link href={`/${category?.slug}/${slug}-${id}`}>
      {isThumbnail && (
        <div className="w-full h-[124px] md:h-[150px] lg:h-[124px] relative mb-2">
          <Image
            src={thumbnail || ''}
            alt={'thumbnail'}
            fill
            quality={70}
            style={{ objectFit: 'cover' }}
            sizes="(max-width: 768px) 100vw, (max-width: 1200px) 50vw, 10vw"
          />
        </div>
      )}
      <div className="text-[16px] text-[#3F4D5C] font-medium line-clamp-2 hover:text-primary">
        {title}
      </div>
    </Link>
  );
};

export default ItemRightTable;
