import React from 'react';
import { CategorySlider, Divider } from '..';
import ItemRightTable from './ItemRightTable';

interface IRightTableData {
  category?: {
    parent?: {
      slug?: string;
      name?: string;
    };
    childrenCategories?: {
      slug?: string;
      name?: string;
    }[];
  };
  data?: {
    id?: string | null;
    slug?: string | null;
    title?: string | null;
    thumbnail?: string | null;
    description?: string | null;
    publishedAt?: string | null;
    category?: { name?: string | null; slug?: string | null } | null;
  }[];
  isLoading?: boolean;
}

const SkelatonRightTableData = () => {
  return (
    <div className="h-96">
      <div className="h-6 bg-slate-300 animate-pulse"></div>
      <Divider />
      <div className="flex gap-4">
        {Array.from({ length: 2 }).map((_, index) => {
          return (
            <div key={index} className="w-full flex flex-col gap-3">
              <div className="h-32 w-full bg-slate-300 animate-pulse"></div>
              {Array.from({ length: 3 }).map((_, index) => {
                return (
                  <div
                    key={index}
                    className="h-10 w-full bg-slate-300 animate-pulse"
                  ></div>
                );
              })}
            </div>
          );
        })}
      </div>
    </div>
  );
};

const RightTableGrid = ({ category, data, isLoading }: IRightTableData) => {
  return (
    <div className="rounded-xl bg-[#E4E4E4] p-5 w-full">
      {isLoading ? (
        <SkelatonRightTableData />
      ) : (
        <>
          <CategorySlider
            parent={category?.parent}
            childrenCategories={category?.childrenCategories}
          />
          <Divider />
          <div className="grid grid-cols-2 gap-x-3">
            {data?.slice(0, 2)?.map((item, index) => (
              <div key={index}>
                <ItemRightTable
                  {...item}
                  isThumbnail={index > 1 ? false : true}
                />
              </div>
            ))}
            {data && data?.length > 2 && (
              <div className="w-full col-span-2">
                <Divider color="border-[#B8B8B8]" />
              </div>
            )}
            {data?.slice(2, 4)?.map((item, index) => (
              <div
                key={index}
                className={`relative ${
                  index === 0 &&
                  'before:-right-1 before:border-r-[1px] before:absolute before:h-full before:border-r-[#B8B8B8]'
                } `}
              >
                <ItemRightTable {...item} />
              </div>
            ))}
            {data && data?.length > 4 && (
              <div className="w-full col-span-2">
                <Divider color="border-[#B8B8B8]" />
              </div>
            )}
            {data?.slice(4, 6)?.map((item, index) => (
              <div
                key={index}
                className={`relative ${
                  index === 0 &&
                  'before:-right-1 before:border-r-[1px] before:absolute before:h-full before:border-r-[#B8B8B8]'
                } `}
              >
                <ItemRightTable {...item} />
              </div>
            ))}
          </div>
        </>
      )}
    </div>
  );
};

export default RightTableGrid;
